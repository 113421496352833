import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardFeeEntryNode, useEmitCardMutation } from 'apollo/main/generated';
import { getErrorData, useGlobalError } from 'helpers';
import { Confirm, Expired, Payment, Pending } from '../VirtualCardManageViews/VirtualCardManageViews';
import { View } from '../VirtualCardManageViews/VirtualCardManageViews.d';

export interface VirtualCardIssueProcessProps {
    amountGive?: string;
    amountReceive?: string;
    cardholderName?: string;
    issueFees?: CardFeeEntryNode;
    onClose?: () => void;
    setView: (s: View) => void;
    view: View;
}

export default function VirtualCardIssueProcess({
    amountGive,
    amountReceive,
    issueFees,
    cardholderName,
    onClose,
    view,
    setView
}: VirtualCardIssueProcessProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();

    const [memo, setMemo] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [receiver, setReceiver] = React.useState('');
    const [time, setTime] = React.useState('');

    const {
        // fixedFee,
        // rate,
        issuerRate,
        issuerFixedFee
    } = issueFees ?? {};

    const [issueCardMutation, { loading: issueCardLoading }] = useEmitCardMutation();

    // const handleConfirmSubmit = () => {
    //     setView(View.Payment)
    // }

    const handleConfirmSubmit = () => {
        if (!amountReceive || !cardholderName) {
            onGlobalError();

            return;
        }

        issueCardMutation({
            variables: {
                input: {
                    amount: amountReceive,
                    cardCurrency: 'usd',
                    paymentCurrency: 'usdcash',
                    cardholderName
                    // phoneCode
                    // phoneNumber
                }
            }
        })
            .then(({ data }) => {
                const issueCard = data?.emitCard;

                if (issueCard?.__typename === 'CardActionNode') {
                    const {
                        // id,
                        // type,
                        // status,
                        createdAt,
                        // eosAccount,
                        // amountToReceive,
                        // currencyToReceive,
                        paymentParams
                    } = issueCard ?? {};

                    const {
                        currency: dataCurrency,
                        receiver: dataReceiver,
                        memo: dataMemo
                        // amount,
                        // expirationDate
                    } = paymentParams ?? {};

                    setMemo(dataMemo ?? '');
                    setCurrency(dataCurrency ?? '');
                    setReceiver(dataReceiver ?? '');
                    setTime(createdAt ?? '');

                    setView(View.Payment);
                } else {
                    const { __typename, errorMessage = '' } = issueCard ?? {};

                    onGlobalError(t([`pageVirtualCard.topUp.error.${__typename}`, errorMessage]));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    if (view === View.Payment)
        return (
            <Payment
                title={t('pageVirtualCard.issue.title')}
                time={time}
                memo={memo}
                receiver={receiver}
                amount={amountReceive}
                currency={currency}
                setView={setView}
            />
        );

    if (view === View.Pending)
        return (
            <Pending
                give={`${amountGive} USDCASH`}
                receive={`$${amountReceive}`}
                rate={`1 USDCASH = ${issuerRate} USD`}
                onClose={onClose}
            />
        );

    if (view === View.Expired)
        return (
            <Expired onClose={onClose} description={t('pageVirtualCard.expired.description', { context: 'issue' })} />
        );

    return (
        <Confirm
            title={t('pageVirtualCard.issue.title')}
            description={t('pageVirtualCard.issue.confirm.description')}
            valueName={cardholderName}
            rate={`1 USDCASH = ${issuerRate} USD`}
            valueIssue={`$${issuerFixedFee}`}
            give={`${amountGive} USDCASH`}
            receive={`$${amountReceive}`}
            onSubmit={handleConfirmSubmit}
            loading={issueCardLoading}
        />
    );
}
