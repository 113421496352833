import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthGenerateDeeplinkMutation, useWaitAccessTokenSubscription } from 'apollo/auth/generated';
import KalynaIcon from 'assets/images/app-kalyna.svg';
import MalinkaIcon from 'assets/images/app-malinka.svg';
import PaycashIcon from 'assets/images/app-paycash.svg';
import { getAppLink, WalletName } from 'helpers/getAppLink/getAppLink';
import { getErrorData, useGlobalError } from 'helpers';
import useAuth from 'hooks/useAuth/useAuth';
import Preloader from 'ui/Preloader/Preloader';
import { QrIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { AuthAppProps, View } from './AuthApp.d';
import styles from './AuthApp.module.scss';

const isTest =
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.includes('dev') ||
    window.location.hostname.includes('test');

const aud = isTest ? 'https://api.test.paycashswap.com' : 'https://api.paycashswap.com';

export default function AuthApp({ onChangeView }: AuthAppProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const { login } = useAuth();

    const [currentRequestId, setCurrentRequestId] = React.useState('');

    const [authGenerateDeeplinkMutation, { loading: authGenerateDeeplinkLoading }] = useAuthGenerateDeeplinkMutation();

    const getDeeplink = React.useCallback(
        (wallet: WalletName) => {
            authGenerateDeeplinkMutation({
                variables: {
                    app: wallet,
                    aud
                },
                context: { clientName: 'auth' }
            })
                .then(({ data: authGenerateDeeplinkData }) => {
                    const authGenerateDeeplink = authGenerateDeeplinkData?.authGenerateDeeplink;

                    if (authGenerateDeeplink?.__typename === 'AuthDeeplinkResponse') {
                        const { deeplink, requestId } = authGenerateDeeplink ?? {};

                        setCurrentRequestId(requestId);

                        if (deeplink) {
                            window.location.href = deeplink;
                        }
                    } else if (authGenerateDeeplink?.__typename === 'ErrorResponse') {
                        const { statusCode, detail } = authGenerateDeeplink ?? {};

                        onGlobalError(`${statusCode}, ${detail}`);
                    } else {
                        onGlobalError(t('pageVirtualCard.auth.error.deepLinkError'));
                    }
                })
                .catch((err) => {
                    onGlobalError(getErrorData(err).message);
                });
        },
        [authGenerateDeeplinkMutation, onGlobalError, t]
    );
    /** After generating QR code and verificationCode awaiting response from server */
    useWaitAccessTokenSubscription({
        skip: !currentRequestId,
        variables: { authRequestId: currentRequestId },
        context: {
            clientName: 'auth'
        },
        onData: ({ data: { data } }) => {
            const waitAccessToken = data?.waitAccessToken;

            if (waitAccessToken?.__typename === 'AuthTokenResponse') {
                const { accessToken = '', accessTokenExpiresAt, refreshToken } = waitAccessToken;

                if (accessToken) {
                    login(accessToken, accessTokenExpiresAt, refreshToken);
                }
            } else if (waitAccessToken?.__typename === 'ErrorResponse') {
                const { statusCode, detail } = waitAccessToken ?? {};

                onGlobalError(`${statusCode}, ${detail}`);
            } else {
                onGlobalError(t('pageVirtualCard.auth.error.getTokenError'));
            }
        }
    });

    const handleClick = (wallet: WalletName) => (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        getDeeplink(wallet);
    };

    return (
        <>
            <div className={styles.Container}>
                {authGenerateDeeplinkLoading && (
                    <div className={styles.Preloader}>
                        <Preloader size={40} />
                    </div>
                )}
                <Button
                    as="a"
                    classes={{ root: styles.Button }}
                    color="gray"
                    href={getAppLink(WalletName.Paycash)}
                    size="large"
                    iconStart={<img src={PaycashIcon} alt="PayCash" />}
                    onClick={handleClick(WalletName.Paycash)}
                    fullWidth
                    disabled={authGenerateDeeplinkLoading}
                >
                    Paycash
                </Button>
                <Button
                    as="a"
                    classes={{ root: styles.Button }}
                    color="gray"
                    href={getAppLink(WalletName.Kalyna)}
                    size="large"
                    iconStart={<img src={KalynaIcon} alt="Kalyna" />}
                    onClick={handleClick(WalletName.Kalyna)}
                    fullWidth
                    disabled={authGenerateDeeplinkLoading}
                >
                    Kalyna
                </Button>
                <Button
                    as="a"
                    classes={{ root: styles.Button }}
                    color="gray"
                    href={getAppLink(WalletName.Malinka)}
                    size="large"
                    iconStart={<img src={MalinkaIcon} alt="Malinka" />}
                    onClick={handleClick(WalletName.Malinka)}
                    fullWidth
                    disabled={authGenerateDeeplinkLoading}
                >
                    Malinka
                </Button>
            </div>
            <div className={styles.Divider}>
                <div className={styles.DividerText}>{t('pageVirtualCard.qr.divider')}</div>
            </div>
            <div className={styles.GroupButton}>
                <Button
                    classes={{ root: styles.GroupButtonItem }}
                    color="gray"
                    iconStart={<QrIcon />}
                    onClick={() => onChangeView?.(View.Qr)}
                    fullWidth
                >
                    {t('pageVirtualCard.auth.app.qrButton')}
                </Button>
            </div>
        </>
    );
}
