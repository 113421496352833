/* eslint-disable import/no-anonymous-default-export */
export default {
    global: {
        bloksIoLink: 'Check at bloks.io',
        copied: 'Copied',
        noData: 'No data',

        btnBookmark_add: 'To bookmarks',
        btnBookmark_remove: 'In bookmarks',

        diff: '24-hour change:',

        sort: 'Sort by',
        search: 'Search',

        transactionType: {
            all: 'All',
            ADD_LIQUIDITY: 'Added',
            REMOVE_LIQUIDITY: 'Removed',
            SWAP: 'Swap',
            TRANSFER: 'Transfer',
            MLNK_COLLECT: 'Accrued MLNK',
            CASH_ISSUE: 'Issue',
            CASH_RETIRE: 'Retirement'
        },

        readMore: 'Find out more',
        linkInNewTab: 'Go the the site {{name}}<0>will open in a new tab</0>',

        token: {
            LIRULED: 'LiST Russia, Saint Petersburg',
            LIRUMOW: 'LiST Russia, Moscow',
            LIRUCEK: 'LiST Russia, Chelyabinsk',
            LIUAIEV: 'LiST Ukraine, Kyiv',
            RUBCASH: 'Rouble',
            USDCASH: 'Dollar',
            EURCASH: 'Euro',
            UAHCASH: 'Hryvna',
            MLNK: 'Malinka',
            'LQ*': 'Liquidity token {{code}}'
        },

        btnDownload: {
            btnAndroid: '<0>Available on</0>Google Play',
            btnIOS: '<0>Available on</0>App Store',
            btnDesktop: '<0>Windows, MacOS</0>Coming soon'
        },

        cardError: {
            text: 'An error occurred during the request.',
            btn: 'Update'
        },

        candlestickParams: {
            low: 'Low',
            high: 'High',
            open: 'Open',
            close: 'Close'
        }
    },

    banners: {
        exchange: {
            title: 'Top up wallet and\nexchange balance'
        },
        paycash: {
            title: 'To invest into pool\nuse the PayCash wallet',
            btnDetails: 'Detailed instructions',
            btnDownload: 'Get the app'
        },

        bankCard: {
            title: 'Top up from the card\nWithdraw to the card',
            btn: 'Go'
        },
        cryptocash: {
            title: 'Tokens, backed by м\u00B2\nmeters of real estate'
        },

        invest: {
            title: 'Start making money\nin just a few simple steps!',
            tag: 'About the project'
        }
    },

    errors: {
        globalError: 'An error occurred.'
    },

    header: {
        language: 'Language',
        nav: {
            info: 'About',
            info_description: 'Find out more about the project and start\ninvesting right now',
            openLetters: 'Open letters',
            whitePaper: 'White paper',
            whitePaper_description: 'White paper and details\non the PayCash ecosystem',
            paycash: 'PayCash',
            paycash_description: 'Use the PayCash cryptowallet\nto invest in liquidity pools',
            depositExchange: 'Top up balance',
            depositExchange_description: 'Instructions for top up balance\nof the PayCash wallet and the exchange',
            cashtokens: 'Crypto cash tokens',
            cashtokens_description: 'Stablecoins backed by\nsquare meters of real estate',
            home: 'Dashboard',
            tokens: 'Tokens',
            pools: 'Pools',
            transactions: 'Transactions',
            search: 'Search',
            bookmarks: 'Bookmarks',
            mlnkInfo: 'Malinka',
            usdcashInfo: 'Stablecoins'
        },
        searchAccount: 'Search for an account'
    },

    footer: {
        help: {
            title: 'Help',
            addLiquidity: 'How to add liquidity?',
            depositToWallet: 'How to deposit to wallet?',
            issueUSDCASH: 'Process of issue'
        },
        more: {
            title: 'More',
            paycashAppLanding: 'PayCash Wallet',
            paycashForStore: 'PayCash for Store'
        },

        contactText: 'If you have any questions, suggestions or complaints, you can contact us at <0>{{email}}</0>'
    },

    pageSearchAccount: {
        title: 'Search results',
        subTitle: 'Found {{count}} occurrence for {{search}}',
        subTitle_plural: 'Found {{count}} occurrences for {{search}}',

        placeholder: 'Account search',

        empty: {
            title: 'Nothing found',
            description: 'Nothing found.\nCheck the correct spelling\nor try another search'
        },

        init: {
            title: 'Input your query'
        }
    },

    pageAccount: {
        title: 'Statistics for the account',

        investments: {
            title: 'Investments into pools',
            helpText: 'Amount of investments\n(Equivalent in USDT)'
        },
        income: {
            title: 'Accrued MLNK',
            total: 'For all time',
            day: 'For 24 hours',
            legend: 'Income in MLNK',
            // helpText: 'Harvested MLNK\nin USDT equivalent',
            helpText: 'Harvested MLNK\nUSDT equivalent - settlement\nat the current price',
            subTitle: 'for 24 hours'
        },
        tokens: 'Tokens',
        pools: 'Pools',

        error: {
            title: 'Error',
            description: 'An error occurred and we unable to load\naccount details. Please refresh the page',
            btn: 'Update'
        },

        notFound: {
            title: 'This account\ndoes not exist'
        },
        empty: {
            title: 'No statistics found\nfor {{account}}',
            description: 'This account has not invested\ninto pools or made any swaps'
        },

        banner: {
            receive: 'There are',
            mln: 'Malinkas',
            account: 'available for {{account}}',
            account_bottom: 'Malinkas available for {{account}}',
            btnCollect: 'Harvest',
            empty: 'All Malinkas\nhas been harvested\nfor this account'
        },

        cashStatistics: {
            issued: 'Issued',
            backing: 'As collateral',
            yield: 'Income for 24 hours',
            btnHistory: 'History',
            btnDeposits: 'Active issues'
        },

        cashDeposits: {
            title: 'Issues',

            card: {
                issued: 'Issued',
                backed: 'As collateral {{ token }}',
                linkBloksIo: 'Check at bloks.io'
            },

            empty: {
                title: 'Empty',
                text: 'No active issues'
            }
        },

        poolDetails: {
            title: 'Pool information',

            empty: {
                title: 'No statistics for {{account}}\nfor this pool if found',
                description: 'This account has not made any\ntransaction in this pool',
                btn: 'Return to statistics for {{ account }}'
            },

            addedToPool: 'Added to pool',
            liquidityTokens: {
                title: 'Liquidity tokens',
                helpText: 'Account share in the pool',
                '%Pool': 'of the pool',
                total: 'Total amount of liquidity tokens',
                account: 'Liquidity tokens for {{account}}'
            },
            totalIncome: {
                title: 'Income for 24 hours',
                title_total: 'For all time',

                params: {
                    turnover: 'Fee {{token}}',
                    mlnk: 'Accrued in MLNK'
                },

                helpText:
                    'Fees + harvested MLNK\nfor this account in the pool\n(USDT equivalent - settlement\nat closing price)'
            },
            feeIncome: {
                title: 'Swap fees',
                helpText:
                    'Swap fees -\nTotal income from swap fees in the pool\nin USDT equivalent\n(Proportional for account share in the pool)'
            }
        }
    },

    searchAccount: {
        placeholder: 'Searching for account',

        result: {
            title: 'Search results',
            description: 'Found {{count}} occurrence',
            description_plural: 'Found {{count}} occurrences',
            btnViewAll: 'See all occurrences'
        },

        empty: {
            title: 'Nothing found',
            description: 'Nothing found.\nCheck the spelling\nor try another search '
        }
    },

    pageBookmarks: {
        title: 'Bookmarks',
        tabs: {
            accounts: {
                title: 'Accounts'
            },
            tokens: {
                title: 'Tokens'
            },
            pairs: {
                title: 'Pools'
            }
        },
        empty: {
            title: 'Empty here',
            text: 'You have not\nbookmarked anything'
        }
    },

    modalCollectMLNK: {
        info: {
            title: 'Harvest Malinkas',
            amount: '{{amount}} MLNK\navailable for {{account}}',
            description:
                'To harvest Malinkas you need to send\n1 USDCASH from this account. You may send 1 USDCASH using\n<0>PayCash</0> app by scanning QR code'
        },

        transaction: {
            qr: 'Scan the QR code',
            params: 'or send manually',
            account: 'Account',
            memo: 'memo'
        },

        btnPay: 'Pay through the app',
        btnPay_desktop: 'Pay',
        btnPay_details: 'Pay manually'
    },

    pageHome: {
        title: 'Statistics',

        error: {
            title: 'Error',
            description: 'An error occurred and we were unable to load\ngraphs. Try to refresh the page',
            btn: 'Refresh'
        },

        cashStatistics: {
            title: 'The issuance of cryptocurrencies',
            backing: 'Collateral',

            yield: 'Avg income per year',

            btnHistory: 'View history',
            btnDetails: 'Details'
        },

        liquidity: {
            title: 'Total investments',
            helpText: 'Amount of all tokens in all pools\n(Equivalent in  USDT)'
        },

        volume: {
            title: 'Swap volume for 24 hours',
            title_week: 'For 7 days',
            helpText: 'Amount of tokens swapped\nfor the last 24 hours\n(Equivalent in USDT)'
        },

        mlnkCollected: {
            title: 'MLNK Statistic',
            helpText: 'MLNK token – reward you can receive\nfor investments into pools ',

            left: 'Remaining to be accrued MLNK',
            burned: 'Burned MLNK',
            collected: 'Accrued MLNK',
            total: 'Total MLNK',
            speed: '{{mlnk}} MLNK in {{blockNum}} block',
            speed_day: 'Accrued MLNK for the last 24h',
            holders: '{{number}} token holder',
            holders_plural: '{{number}} token holders'
        },

        mlnkPrice: {
            title: 'MLNK price',
            change: 'Change per',
            timeframe: {
                DAY: '1 day',
                HOUR: '1 hour',
                FIVE_MINUTES: '5 min.'
            },
            volatility: {
                day: '24 hours',
                week: '7 days',
                month: '30 days'
            }
        },

        topTokens: {
            title: 'Top tokens by liquidity provided',
            helpText: 'Tokens with the largest investments',
            btnViewAll: 'Look up all tokens',

            params: {
                price: 'Price',
                liquidity: 'Investment volume',
                volume: 'Swap volume',
                transactions: 'Number of transactions'
            }
        },

        topPools: {
            title: 'Top pools for 24 hours',
            subTitle: 'APY equivalent',
            helpText: 'Pools with the most APY\nfor the last 24 hours',
            btnViewAll: 'Look up all pools',
            params: {
                profitability: 'Income',
                price: 'Price {{ currency }}',
                liquidity: 'Investment Volume',
                volume: 'Swap Volume'
            }
        }
    },

    pageTokens: {
        title: 'Tokens',

        error: {
            title: 'Error',
            description: 'An error occurred and we were unable to load\ntokens. Try to refresh the page',
            btn: 'Refresh'
        },

        empty: {
            title: 'Nothing found',
            description: 'Nothing found. Please\ncheck the spelling or\ntry another search'
        },

        table: {
            header: {
                token: {
                    title: 'Token',
                    title_ASC: 'By token name A-Z',
                    title_DESC: 'By token name Z-A'
                },
                liquidity: {
                    title: 'Investment Volume',
                    title_ASC: 'Investment Volume ascending',
                    title_DESC: 'Investment Volume descending'
                },
                volume: {
                    title: 'Swap Volume (24 hours)',
                    title_ASC: 'Swap Volume 24h ascending',
                    title_DESC: 'Swap Volume 24h descending'
                },
                price: {
                    title: 'Price',
                    title_ASC: 'Price ascending',
                    title_DESC: 'Price descending'
                },
                priceChange: {
                    title: 'Price change (24 hours)',
                    title_ASC: 'Price change 24h ascending',
                    title_DESC: 'Price change 24h descending'
                }
            }
        }
    },

    pageToken: {
        error: {
            title: 'Error',
            description: 'Error occurred and we were unable to load\ntoken. Try to refresh the page',
            btn: 'Refresh'
        },

        empty: {
            title: 'Token not found',
            description: 'The specified token was not found. Please\ncheck the spelling or try another search',
            btn: 'To the list'
        },

        liquidity: {
            title: 'Investment Volume',
            helpText: 'Total Investments into token\n{{code}} in all pools\n(Equivalent in USDT)'
        },

        volume: {
            title: 'Swap volume in 24 hours',
            title_week: 'For 7 days',
            helpText: 'Swap Volume for token\n{{code}} for the last 24 hours\n(Equivalent in USDT)'
        },

        transactions: {
            title: 'Number of transactions',
            subTitle: 'for 24 hours'
        },

        price: {
            title: 'Price',
            helpText:
                'Price of token is calculated on the basis of\nminimal swap route of {{code}} into USDCASH.\nIf there are several routes of the same length,\nthe route with the minimum resulting price of {{code}} is chosen.\nUSDCASH value is converted at the exchange rate to USDT'
        },

        pairs: {
            title: 'Pools',
            colls: {
                type: 'Transaction type',
                liquidity: 'Investment volume',
                volume: 'Swap volume (24 hours)',
                fee: 'Fees',
                income: 'Income'
            }
        },

        info: {
            title: 'Token information',

            name: 'Token name',
            contract: 'EOS smart contract'
        }
    },

    pagePool: {
        title: 'Pool',
        liquidityToken: {
            title: 'Liquidity token',
            helpText: 'Tokens which are distributed among investors\nproportionally to their investments into pools',
            released: 'Liquidity tokens issued'
        },

        error: {
            title: 'Error',
            description: 'An error occurred and we were not able to load\npool details. Try to refresh the page',
            btn: 'Refresh'
        },

        empty: {
            title: 'Pool not found',
            description: 'The specified pool was not found. Please\ncheck the spelling or\ntry another search',
            btn: 'To the list'
        },

        token: {
            price: 'Price',
            pool: 'In pool'
        },

        liquidity: {
            title: 'Investment Volume',
            subTitle: '',
            helpText: 'Investment volume of token\n{{code1}} and {{code2}} in the pool\n(Equivalent in USDT)'
        },

        volume: {
            title: 'Swap volume for 24 hours',
            title_week: 'For 7 days',
            helpText: 'Swap volume for tokens {{code1}}\nand {{code2}} for the last\n24 hours (Equivalent in USDT)'
        },

        income: {
            title: 'Income for 24 hours',
            title_year: 'APY equivalent',
            subTitle: '',
            helpText: 'Fees + accrued MLNK\nin USDT equivalent',

            tokenTurnover: 'Fees {{token}}',
            mlnkIncome: 'Accrued MLNK'
        },

        info: {
            title: 'Pool information',

            name: 'Pool name',
            address: 'smart contract account',
            contract: 'Smart contract {{token}}'
        }
    },

    transactionsTable: {
        title: 'Transactions',

        error: {
            title: 'Error',
            description: 'An error occurred and we were unable to load\ntransactions. Try to refresh the page',
            btn: 'Refresh'
        },

        empty: {
            title: 'Nothing found',
            description: 'Nothing is found for your query.'
        },

        params: {
            type: {
                title: 'Type',
                title_ASC: 'Type ascending',
                title_DESC: 'Type descending'
            },
            account: {
                title: 'Account',
                title_ASC: 'Account name 1-5 and A-Z',
                title_DESC: 'Account name Z-A and 5-1'
            },
            sender: {
                title: 'Sender'
                // title_ASC: 'Sender ascending',
                // title_DESC: 'Sender descending',
            },
            receiver: {
                title: 'Receiver'
                // title_ASC: 'Receiver ascending',
                // title_DESC: 'Receiver descending',
            },
            volume: {
                title: 'Volume',
                title_ASC: 'Volume ascending',
                title_DESC: 'Volume descending'
            },
            date: {
                title: 'Date and time',
                title_ASC: 'Date ascending',
                title_DESC: 'Date descending'
            },
            cashAmounts: {
                title_CASH_ISSUE: 'As collateral',
                title_CASH_RETIRE: 'Received'
            }
        }
    },

    poolsTable: {
        title: 'Pools',
        error: {
            title: 'Error',
            description: 'An error occurred and were unable to load\npools. Try to refresh the page',
            btn: 'Refresh'
        },

        empty: {
            title: 'Nothing found',
            description: 'Nothing was found for your query. Please\ncheck the spelling or try\nanother search',
            description_short: 'Nothing found for your query.'
        },

        param: {
            token: {
                title: 'Pools',
                title_ASC: 'Pool ID ascending',
                title_DESC: 'Pool ID descending'
            },
            liquidity: {
                title: 'Investment Volume',
                title_ASC: 'Investment Volume ascending',
                title_DESC: 'Investment Volume descending'
            },
            volume: {
                title: 'Swap Volume (24 hours)',
                title_ASC: 'Swap Volume 24h ascending',
                title_DESC: 'Swap Volume 24h descending'
            },
            income: {
                title: 'Income (24 hours)',
                title_ASC: 'Income for 24h ascending',
                title_DESC: 'Income for 24h descending'
            },
            apy: {
                title: 'APY',
                title_ASC: 'APY ascending',
                title_DESC: 'APY descending'
            }
        }
    },

    pageError: {
        error: 'Error',

        404: {
            title: 'Page\nnot found',
            description: 'Maybe the page does not exist\nor you entered wrong\naddress in your browser'
        },
        500: {
            title: 'Internal\nserver error',
            description: 'An unforeseen error occurred\nwith server. Please wait:\nthe error will be fixed.'
        },

        btnHome: 'Return to the home page'
    },

    pageInfo: {
        intro: {
            title: '<0>Invest in</0><1>\ncryptocurrency\nthe easy way</1>',
            description: 'Start investing right away\nand receive Malinka cryptotoken bonuses',
            btnStart: 'Start investing',
            btnMore: 'Learn more'
        },

        about: {
            title: 'The earning mechanic\nis very simple!',
            text: [
                'Users, who are also called liquidity suppliers, invest their cryptocurrency in any currency pair (pool) in equal proportion! Thus they provide a maximum supply of these cryptocurrencies (liquidity) in an exchange service for all who want to swap currencies within this pair. ',
                'Depending on the amounts invested and the currency pair chosen, each user receives liquidity tokens (LQA, LQB, LQC etc). They confirm his share in a given pool and entitle him to receive income from commission for each exchange operation in proportion to his investment in this pool. '
            ]
        },

        statistics: {
            title: 'Statistics',

            liquidity: {
                title: 'Total investment into pools',
                title_short: 'Investments'
            },
            volume: {
                title: 'Total exchange over the last 24 hours',

                title_short: 'Total exchange'
            },
            mlnk: {
                title: 'MLNK Statistics'
            },
            mlnkPrice: {
                title: 'MLNK Price'
            }
        },

        feeIncome: {
            title: 'How are the earnings calculated?',
            description:
                'For each exchange operation in the pools\na commission of 0.25% of the exchange sum is charged.',

            fee: 'Commission',
            investor: '<0>0.2%</0>\nIs distributed proportionally\namong users-suppliers of\nliquidity',

            platform: '<0>0.05%</0>\nIs directed towards the burning\nof the reward token\nCrypto Malinka (MLNK)'
        },

        mlnkIncome: {
            title: 'In addition to commissions income',
            description: 'liquidity suppliers also receive supertokens -\nCrypto Malinka (MLNK) as a reward!',
            btnCalc: 'Pool earning calculator'
        },

        aboutMLNK: {
            title: '<0>What is crypto</0><1>\nMalinka (MLNK)?</1>',
            description: [
                'In addition to the main income in the form of a share of exchange fees, all users who have invested their crypto cash PayCash or LiST tokens in the pools receive a super token - Crypto Malinka (MLNK) as a reward!',
                'Each block in the blockchain (0.5 seconds) generates a certain amount of Malinka tokens from August 21, 2021, to August 29, 2081, starting with 100 in the first block and gradually decreasing to 0.1 Malinka in the last one!',
                'The token Malinka (MLNK) is credited as a reward only to MLNK pools with one of the tokens from the White List. Crypto Malinka is distributed among the pools based on their weight in the total investments! Then it is distributed among the investors in the pools according to their shares!'
            ],

            fee: '<0>0.05%</0>\n(which is equal to 20% from all exchange pool commissions) are converted to US Dollars and are directed towards purchase of Crypto Malinka for the purpose of burning it!',

            result: '<0>Result:</0>\nCrypto Malinka will be ensured constant liquidity, dependent on how intensively the currency exchange volumes and the number of users grow, its market price increase and decrease of its quantity available! ',

            banner: {
                text: '<1>Availability</1> <0>2 055 375</0><1>Malinka</1>',
                account: 'for user.pcash',
                btn: 'Gather'
            }
        },

        pools: {
            title: 'Pools receiving malinka',
            description: 'All pools that have MLNK',
            hint: '- this is how pools receiving malinka are marked in the pool list',

            btnShowAll: 'Show All',
            btnCollapse: 'Collapse'
        },

        start: {
            title: 'Start earning\nin just a few easy steps!',

            step: '{{ count }} step',

            btnVideo: 'Video manual',
            btnNext: 'Next step',
            btnBack: 'Previous step',

            step1: {
                title: 'Download the app',
                text: 'To start investing you need\nto download the PayCash app',
                btnSite: 'Download from PayCash website'
            },

            step2: {
                title: 'Create an EOS account',
                text: 'After you have downloaded the app, you need to create a wallet\n\nYou may also use an existing EOS wallet by inserting its private key '
            },

            step3: {
                title: 'Choose a pool',
                text: 'On the website PayCash Swap go to the "Pools" section a select a suitable pool for your investments.\n\nYou may choose a pool based on its profitability over the last 24 hours or equivalent profitability per year.\n\nIf you go to the pool page you will be able to see detailed information on its profitability and other parameters '
            },

            step4: {
                title: 'Top up your balance',
                text: 'Once you select the token pool, you will have to purchase the tokens specified on its page proportionally in proportion, 50% of your investment for each token.',
                list: [
                    'You may purchase cryptocurrency in the "Buy" section',
                    'Purchase EOS through Binance cryptoexchange or a currency exchange service and convert them in the "Swap" section to the desired cryptocurrency.'
                ]
            },

            step5: {
                title: 'Go to the investment section',
                text: 'After buying the necessary tokens in the PayCash app go to the "Other" section and then go to "SWAP" section.'
            },

            step6: {
                title: 'Add liquidity to the pools',
                text: 'Choose the pair of tokens that you have purchased. After you choose the tokens, two fields where you can set the desired investment amount for each token will appear. '
            },

            step7: {
                title: 'Track your income',
                text: 'Use the PayCash Swap site to track your income and crypto Malinka.',
                list: [
                    'Go to PayCash Swap website',
                    'Push the "Search for an account" button',
                    'Enter the name of your account in the search field and, once the search results are loaded, click on the desired name.'
                ]
            },
            step8: {
                title: 'Gather Malinka',
                text: 'On the page with your Account details you may gather Malinka that you have received for you investment into the liquidity pools.',
                list: [
                    'Click on the banner with Malinka',
                    'Pay 1 USDCASH through the app or send it by scanning the QR code.'
                ]
            }
        }
    },

    pageOpenLetters: {
        title: 'Open letters',
        letter1: {
            title: 'Message to the community',
            date: 'Feb. 26, 2024',
            text: [
                'Dear crypto Community,',
                'Feb 22, 2024, the Recover+ team successfully froze 2 million EOS of compromised funds on EOS EVM and is already in a progress of a final recovery.',
                'The PayCash team expresses our deepest gratitude to the Recover+ team for their continued support and expertise in ensuring the restoration of justice. This not only highlights the effectiveness of the Recover+ initiative, but also shows how reliable and advanced the EOS ecosystem is.',
                'As a sign of our appreciation and commitment to creating a more secure blockchain environment, we commit to donate 5% of all recovered funds to further support and develop the Recover+ project.\nThis gesture reflects our contribution to support ongoing efforts to strengthen security measures and protect the EOS community from possible threats, as well as the full popularization of the EOS blockchain.',
                'With sincere thanks,\nthe PayCash Development Team'
            ]
        }
    },

    pageDepositExchange: {
        title: 'Top up wallet and\nexchange balance',
        description: 'Top up of PayCash wallet with MLNK, EOS, and USDT\ntokens via Binance and WhiteBit exchanges',

        btnStart: 'Go to instruction',

        exchangeList: 'Also you can buy cryptocurrency on other exchanges',
        step: '{{step}} step',
        btnNext: 'Next',
        btnBack: 'Back',

        tutorial: {
            deposit: 'Top up wallet',
            withdraw: 'Top up exchange',

            token: 'Token',
            exchange: 'Exchange',

            tooltip_disabledGateway: 'There is no token\non this exchange'
        },

        deposit: {
            EOS: {
                whitebit: {
                    step_1: {
                        title: 'Go to the WhiteBit',
                        text: 'To top up WhiteBit balance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the WhiteBIt site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the Balances',
                        text: 'To top up your wallet on the exchange go to to the "Spot" section in the "Balances" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Select EOS',
                        text: 'After going to the balances section, use the search among the tokens, and find the EOS token in the list and click the "Withdrawal" button'
                    },
                    step_5: {
                        title: 'Transfer to the wallet',
                        text: 'After you have selected a token, you need to fill the address of your wallet, memo (description of the transfer, you can specify anything), and the amount'
                    }
                },

                binance: {
                    step_1: {
                        title: 'Go to the Binance',
                        text: 'To transfer EOS to the Binance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the Binance site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Purchase EOS with a bank card',
                        text: 'In the dropdown menu "Buy Crypto" select the "Credit/Debit Card" item'
                    },
                    step_4: {
                        title: 'Specify the amount to be purchased',
                        text: 'On an opened page in the field "Receive" choose the EOS, in the field "Spend" enter the amount of funds for which you want to buy EOS\n\nOr specify the amount of EOS you want to buy directly, the amount shall update automatically.'
                    },
                    step_5: {
                        title: 'Pay',
                        text: 'Once you specify the desired amount of EOS, a payment page will open. There push the "Add new card" button and enter your card information.'
                    },
                    step_6: {
                        title: 'Transfer EOS to PayCash',
                        text: 'Once you purchase EOS, you will need to transfer it to your PayCash wallet. For that go to the "Wallet" section and push the "Withdraw" button.'
                    },
                    step_7: {
                        title: 'Specify your wallet information',
                        text: 'On the opened page select the coin to withdraw and specify your PayCash account (it maybe found in the upper left-hand corner of the app). Select the EOS network and specify the amount to be withdraw. It is not necessary to fill in the MEMO field\n\nIn a matter of minutes the balance will be topped up'
                    }
                }
            },

            MLNK: {
                whitebit: {
                    step_1: {
                        title: 'Go to the WhiteBit',
                        text: 'To top up WhiteBit balance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the WhiteBIt site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the Balances',
                        text: 'To top up your wallet on the exchange go to to the "Spot" section in the "Balances" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Select MLNK',
                        text: 'After going to the balances section, use the search among the tokens, and find the MLNK token in the list and click the "Withdrawal" button'
                    },
                    step_5: {
                        title: 'Transfer to the wallet',
                        text: 'After you have selected a token, you need to fill the address of your wallet, memo (description of the transfer, you can specify anything), and the amount'
                    }
                }
            },

            TETHER: {
                whitebit: {
                    step_1: {
                        title: 'Go to the WhiteBit',
                        text: 'To top up WhiteBit balance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the WhiteBIt site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the Balances',
                        text: 'To top up your wallet on the exchange go to to the "Spot" section in the "Balances" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Select USDT',
                        text: 'After going to the balances section, use the search among the tokens, and find the USDT token in the list and click the "Withdrawal" button'
                    },
                    step_5: {
                        title: 'Transfer to the wallet',
                        text: 'After you have selected a token, you need to fill the address of your wallet, memo (description of the transfer, you can specify anything), and the amount'
                    }
                }
            }
        },
        withdraw: {
            EOS: {
                whitebit: {
                    step_1: {
                        title: 'Go to the WhiteBit',
                        text: 'To top up WhiteBit balance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the WhiteBIt site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the Balances',
                        text: 'To top up your wallet on the exchange go to to the "Spot" section in the "Balances" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Select EOS',
                        text: 'After going to the balances section, use the search among the tokens, and find the EOS token in the list and click the "Deposit" button'
                    },
                    step_5: {
                        title: 'Send tokens',
                        text: 'After you select the token , you will receive the wallet address and MEMO that you need to specify when transferring EOS from PayCash wallet'
                    }
                },
                binance: {
                    step_1: {
                        title: 'Go to the Binance',
                        text: 'To transfer EOS to the Binance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the Binance site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the wallet',
                        text: 'To top up your wallet on the exchange go to to the "Fiat and Spot" section in the "Wallet" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Go to deposit crypto',
                        text: 'Then press "Deposit" button'
                    },
                    step_5: {
                        title: 'Select EOS',
                        text: 'In the "Coin" drop-down list, select the EOS token, in the "Network" section, select "EOS"',
                        alert: 'To send EOS from the PayCash app, you must select EOS network'
                    },
                    step_6: {
                        title: 'Send EOS',
                        text: 'After you select the token and the network, you will receive the wallet address and MEMO that you need to specify when transferring EOS from PayCash wallet'
                    }
                }
            },

            MLNK: {
                whitebit: {
                    step_1: {
                        title: 'Go to the WhiteBit',
                        text: 'To top up WhiteBit balance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the WhiteBIt site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the Balances',
                        text: 'To top up your wallet on the exchange go to to the "Spot" section in the "Balances" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Select MLNK',
                        text: 'After going to the balances section, use the search among the tokens, and find the MLNK token in the list and click the "Deposit" button'
                    },
                    step_5: {
                        title: 'Send tokens',
                        text: 'After you select the token , you will receive the wallet address and MEMO that you need to specify when transferring MLNK from PayCash wallet'
                    }
                }
            },

            TETHER: {
                whitebit: {
                    step_1: {
                        title: 'Go to the WhiteBit',
                        text: 'To top up WhiteBit balance, go to the site by clicking the button below'
                    },
                    step_2: {
                        title: 'Authorize',
                        text: 'You have to authorize on the WhiteBIt site. Enter your account or register a new one'
                    },
                    step_3: {
                        title: 'Go to the Balances',
                        text: 'To top up your wallet on the exchange go to to the "Spot" section in the "Balances" drop-down menu on the right side of the top menu'
                    },
                    step_4: {
                        title: 'Select USDT',
                        text: 'After going to the balances section, use the search among the tokens, and find the USDT token in the list and click the "Deposit" button'
                    },
                    step_5: {
                        title: 'Send tokens',
                        text: 'After you select the token , you will receive the wallet address and MEMO that you need to specify when transferring USDT from PayCash wallet'
                    }
                }
            }
        }
    },

    pageAboutCashTokens: {
        intro: {
            title: '<0>Stablecoins</0><1>\nbacked by</1><2>200%</2>',
            btnMore: 'Details'
        },

        commission: {
            text: '<0>No hidden\nfees</0> and asterisks\non all transfers',
            subtext: "*that's true, none"
        },

        statistics: {
            title_link: 'No fees',
            params: {
                issued: 'issued',
                backed: 'As collateral ',
                yield: 'Yield'
            }
        },

        guarantees: {
            title: 'Guaranteed crypto cash exchange',
            title_link: 'Guaranteed exchange',

            description:
                'Any user can reliably exchange their cryptocurrencies for one of the tokens (depending on the issued tokens)',
            subtext: 'A user who has issued cryptocurrencies can reclaim their collateral by burning the issued tokens',
            list: ['USDCASH to USDT', 'RUBCASH to RUBLES', 'RMBCASH to RMBC']
        },

        income: {
            title: 'Increase your\u00A0income',
            title_link: 'Yield',

            description:
                'Get enhanced yield from the collateral token pool by minting cryptocurrencies. You can also contribute minted cryptocurrencies to the pool for even greater returns on your investments.'
        },

        inheritance: {
            title: 'Inheritance',
            title_link: 'Inheritance',

            description:
                'Set up your inheritors, their share and inheritance periods in just several clicks without any legal documents and extra costs.',
            btnDownload: 'Download app',

            example: {
                duration: {
                    label: 'set period of inactivity',
                    value: '365 days'
                },
                date: {
                    label: 'Date of inheritance execution',
                    value: '28 Feb. 2022'
                }
            }
        },

        applications: {
            title: 'Use one of the recommended apps to take advantage of all the benefits of cryptocurrencies.',

            title_link: 'Recommended applications'
        },

        tutorials: {
            title_link: 'FAQ',

            btnDownload: 'Download app',

            step: 'Step {{ stepNumber }}',

            content: {
                issue: {
                    title: 'Issue',

                    steps: [
                        {
                            title: 'Go to section',
                            content:
                                'Use one of the recommended applications to issue cryptocurrencies. Go to the "Cryptocurrency Issuance" section. On the opened screen, click on the "New Issuance" button'
                        },
                        {
                            title: 'Choose a token',
                            content: 'Choose the token you want to create'
                        },
                        {
                            title: 'Fill in the fields',
                            content:
                                'Specify the amount of one of the tokens you want to invest. The amount of the second token will be filled automatically based on the current pool rate'
                        },
                        {
                            title: 'Confirmation',
                            content:
                                'Carefully check the number of tokens to be deducted from your wallet, as well as the amount to be received. Confirm the transaction if everything is correct or edit the details'
                        },
                        {
                            title: 'Done!',
                            content:
                                'In case of a successful issuance, you will see a transaction confirmation. The window contains information about the deposit amount, the issued quantity of tokens, and the transaction ID. Copy the ID or visit bloks.io to view the transaction on the EOS network'
                        }
                    ]
                },

                burn: {
                    title: 'Retirement',

                    steps: [
                        {
                            title: 'Go to section',
                            content:
                                'Use an application from the recommended list for burning cryptocurrencies. Go to the "Cryptocurrency Burning" section. Choose one of the burns from the list and click the "Return Deposit" button'
                        },
                        {
                            title: 'Fill in the fields',
                            content:
                                'Next, you need to fill in the "Refund Amount" field. Enter the amount of tokens you want to refund there'
                        },
                        {
                            title: 'Confirmation',
                            content:
                                'Carefully check the number of tokens that will be deducted from your wallet, as well as the amount to be received. Confirm the transaction if everything is correct or edit the details'
                        },
                        {
                            title: 'Done!',
                            content:
                                'In case of a successful refund, you will see a transaction confirmation. The window contains information about the amount of tokens refunded and received, as well as the transaction ID. Copy the ID or visit bloks.io to view the transaction on the EOS network'
                        }
                    ]
                },

                buy: {
                    title: 'Guarantee exchange',

                    steps: [
                        {
                            title: 'Go to section',
                            content:
                                'Go to the "Guaranteed VirtualCard" section. On the opened screen, select the token you want to exchange'
                        },
                        {
                            title: 'Amount to exchange',
                            content:
                                'Next, you need to fill in the "Amount to be Returned" field. Enter the amount of tokens you want to exchange. The amount to be returned should be a multiple of the amount specified above'
                        },
                        {
                            title: 'Confirmation',
                            content:
                                'Carefully review the exchange amount. If everything is correct, confirm the exchange or edit the information'
                        },
                        {
                            title: 'Done!',
                            content:
                                'In case of a successful exchange, you will see a transaction confirmation. The window contains information about the tokens and the exchange amount, as well as the transaction ID. Copy the ID or visit bloks.io to view the transaction on the EOS network'
                        }
                    ]
                }
            }
        }
    },
    pageExchange: {
        title: 'Withdrawal and deposit\nof the wallet',
        badge: {
            label: 'Beta version',
            content: 'In case of errors, please contact support'
        },
        sell: 'Withdraw',
        buy: 'Deposit',
        currency: 'Currency',
        minAmount: 'Minimum',
        maxAmount: 'Maximum',
        commission: 'Commission',
        divisible: 'The value must be divisible by {{value}}',
        cardNumber: 'Card number',
        walletEos: 'EOS wallet',
        walletTrx: 'TRC-20 wallet',
        email: 'Email',
        emailDescription: 'Enter your email, and we will send a link\nto the page for tracking status.',
        optional: 'Optional',
        submit: 'Proceed',
        support: 'Support',
        error: {
            ExchangePairNotPresentError: 'VirtualCard pair not present',
            ExchangePairNotFoundError: 'VirtualCard pair not found',
            CurrencyNotFoundError: 'Currency not found',
            FiatMethodNotFoundError: 'Fiat method not found',
            InvalidTransferParamsError: {
                crypto: 'The account you specified was not found',
                card: 'Card number is not correct'
            },
            UnderMaintenanceError: 'Service under maintenance'
        },
        amount: {
            enterAmount: 'Enter amount',
            give: 'Give',
            receive: 'Receive'
        },
        techWorks: {
            title: 'Technical work is in progress',
            estimatedTime: 'Estimated time of completion'
        }
    },
    pageStatus: {
        success: 'A link to track the status of the application has been sent to the specified mail',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        error: {
            status: 'An error occurred while processing the transaction,\ncontact technical support',
            expired: 'You have not paid your invoice. Payment\nlink expired',
            ExchangeNotFoundError: 'VirtualCard not found'
        },
        info: {
            refunded: 'This application has been refunded.'
        },
        receiverAccount: 'Receiver account',
        receiverCard: 'Receiver card',
        willBeReceived: 'Will be received',
        received: 'Received',
        paid: 'Paid',
        refunded: 'Refunded',
        transactionId: 'Transaction ID',
        email: 'Email',
        emailText: 'Enter your email and we will send a link to the status tracking page',
        submit: 'Proceed',
        refresh: 'VirtualCard again',
        support: 'Support'
    },

    pageVirtualCard: {
        auth: {
            title: 'Virtual card',
            description:
                'Top up your card with USDCASH and pay for purchases\nonline and offline using Apple Pay and Google Pay',
            button: {
                login: 'Login',
                logout: 'Logout'
            },
            qr: {
                title: 'QR Login',
                description: 'To log in or register,\n<0>select an app and scan the QR code</0>',
                appButton: 'Login with app',
                error: {
                    failLoading: 'Failed\nto load QR code'
                },
                reload: 'Reload'
            },
            app: {
                title: 'Authorization',
                description: 'For quick and convenient authorization,\nwe recommend using one of the apps',
                qrButton: 'Sign in with QR code'
            },
            error: {
                deepLinkError: 'Error receiving deeplink',
                getTokenError: 'Error getting token'
            }
        },
        qr: {
            divider: 'or'
        },
        app: {
            description: 'Select an app',
            download: {
                title: "Don't have the app?",
                link: 'Download'
            }
        },
        card: {
            account: 'Account',
            balance: 'Balance',
            description: 'To view the card details - click on it',
            name: 'Name on card',
            number: 'Card number',
            expiration: 'Expiration date',
            error: {
                title: 'Error',
                description: 'An error occurred while loading'
            }
        },
        history: {
            title: 'Transaction history',
            spend: 'Payment',
            topUp: 'Card top up'
        },
        amount: {
            title: 'Top up amount',
            give: 'You give',
            receive: 'You receive',
            rate: 'Exchange rate',
            min: 'Min.',
            max: 'Max.',
            summaryGive: 'Total to pay',
            summaryReceive: 'Will be received'
        },
        issue: {
            title: 'Card issuance',
            name: {
                label: 'Name on card',
                placeholder: 'Enter full name',
                helperText: 'Enter the name in 2 words in English.\nFor example - John Doe'
            },
            terms: {
                topUp: 'Card top up',
                topUpMin: 'Minimum top up amount'
            },
            confirm: {
                description: 'You are about to issue a virtual card'
            },
            error: {
                CardEmissionDisallowedError: 'Card emission disallowed',
                InvalidCardholderError: 'Invalid cardholder',
                UnderMaintenanceError: 'Under maintenance'
            }
        },
        topUp: {
            title: 'Top up card',
            description: 'You are about to top up a virtual card',
            error: {
                CardEmissionDisallowedError: 'Card emission disallowed',
                InvalidCardholderError: 'Invalid cardholder',
                UnderMaintenanceError: 'Under maintenance'
            }
        },
        confirm: {
            give: 'You will be charged',
            receive: 'You will receive on the card'
        },
        payment: {
            summary: 'Total to pay',
            expiration: 'Account expiry date',
            wallet: 'Wallet',
            memo: 'MEMO',
            value: 'Amount to pay',
            attention:
                'Please enter the wallet, amount, and MEMO correctly.\nIncorrect input may result in loss of funds.',
            app: {
                description: 'Pay via app',
                qrButton: 'Using QR code and details'
            }
        },
        pending: {
            title: 'Processing',
            description:
                'You have paid for the order. You will receive a transfer to the card after the order is processed.' +
                'It usually takes 5-10 minutes.',
            deposit: 'Top up',
            give: 'You have been charged',
            receive: 'Will be received',
            rate: 'Exchange rate',
            transactionId: 'Transaction ID'
        },
        expired: {
            title: 'Order not paid',
            description: 'You have not paid for the order to top up your virtual card. Close this window and try again',
            description_issue:
                'You have not paid for the order for issuing a virtual card. Close this window and try again'
        },
        status: {
            title: 'Virtual card',
            pending: {
                title: 'Your order is being processed',
                description: 'Your order is being processed,\nyour card will be ready soon'
            },
            unpaid: {
                title: 'Waiting for payment',
                description: 'You have not paid for your application\nto issue a virtual card'
            },
            fail: {
                title: 'Error',
                description: 'An error occurred while issuing\nthe virtual card.Contact tech support'
            }
        },
        terms: {
            title: 'Virtual\nMasterCard',
            list: [
                {
                    label: 'Type',
                    value: 'Virtual card of the international\npayment system MasterCard'
                },
                {
                    label: 'Country',
                    value: 'Hong Kong'
                },
                {
                    label: 'Where this card can be used for payment',
                    value: 'In all countries except China'
                },
                {
                    label: 'Card currency',
                    value: '$ • USD'
                },
                {
                    label: 'Card top up currency',
                    value: 'USDCASH'
                },
                {
                    label: 'Card issuance',
                    value: '$10'
                },
                {
                    label: 'Card maintenance',
                    value: 'Free'
                },
                {
                    label: 'Card top up fee',
                    value: '1%'
                },
                {
                    label: 'Cross-border transaction fee',
                    value: '0'
                },
                {
                    label: 'Minimum top-up amount',
                    value: '$100'
                },
                {
                    label: 'Cash withdrawal at ATMs',
                    value: 'Not available'
                },
                {
                    label: 'Maximum balance',
                    value: 'Unlimited'
                },
                {
                    label: 'Limits on the number and amount of transactions',
                    value: 'None'
                }
            ]
        },
        button: {
            continue: 'Continue',
            done: 'Done',
            toPay: 'Proceed to payment',
            terms: 'Terms and limits',
            topUp: 'Top up',
            refresh: 'Refresh',
            support: 'Contact tech support'
        }
    }
};
