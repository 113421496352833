import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import UsdcashIcon from 'assets/images/tokens/usdcash.svg';
import DollarIcon from 'assets/images/tokens/dollar.svg';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import TextField from 'ui/TextField/TextField';
import styles from './VirtualCardAmount.module.scss';

export interface VirtualCardAmountProps {
    amountGiveValue: string;
    onAmountGiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    amountReceiveValue: string;
    onAmountReceiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isError?: boolean;
    // give?: string | null;
    // giveOptions?: TokensDropdownProps<string>['options'] | null;
    // onGiveChange?: TokensDropdownProps<string>['onChange'];
    // receive?: string | null;
    // receiveOptions?: TokensDropdownProps<string>['options'] | null;
    // onReceiveChange?: TokensDropdownProps<string>['onChange'];
    // rate?: number;
    // InputPropsGive?: TextFieldProps;
    // InputPropsReceive?: TextFieldProps;
}

export default function VirtualCardAmount({
    isError,
    amountGiveValue,
    onAmountGiveChange,
    amountReceiveValue,
    onAmountReceiveChange
}: VirtualCardAmountProps) {
    const [t] = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={cn(styles.Item, styles.ItemLeft)}>
                <TextField
                    classes={{ container: styles.InputContainer }}
                    placeholder={t('pageVirtualCard.amount.give')}
                    adornmentEnd={<img className={styles.Icon} src={UsdcashIcon} alt="Usdcash" />}
                    value={addThousandsSeparator(amountGiveValue)}
                    onChange={onAmountGiveChange}
                    autoComplete="off"
                    isError={isError}
                />
            </div>
            <div className={cn(styles.Item, styles.ItemRight)}>
                <TextField
                    classes={{ container: styles.InputContainer }}
                    placeholder={t('pageVirtualCard.amount.receive')}
                    adornmentEnd={<img className={styles.Icon} src={DollarIcon} alt="Dollar" />}
                    value={addThousandsSeparator(amountReceiveValue)}
                    onChange={onAmountReceiveChange}
                    autoComplete="off"
                    isError={isError}
                />
            </div>
        </div>
    );
}
