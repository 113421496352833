import React from 'react';
import cn from 'classnames';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import VirtualCardIssueProcess from '../VirtualCardIssueProcess/VirtualCardIssueProcess';
import { View } from '../VirtualCardManageViews/VirtualCardManageViews.d';
import styles from './VirtualCardIssueModal.module.scss';

export default function VirtualCardIssueModal({ open, setOpen, context, ...props }: ReturnType<typeof useDialog>) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isTouchableDevice = useIsTouchableDevice();

    const [view, setView] = React.useState(View.TopUp);

    const handleClose = () => setOpen(false);

    return (
        <Dialog
            classes={{ container: cn(styles.Container, isTouchableDevice && styles.ContainerIsTouch) }}
            open={open}
            onOpenChange={handleClose}
            fullScreen={isMobileOrLess || isTouchableDevice}
            transition={isMobileOrLess || isTouchableDevice ? 'slide-down' : undefined}
            {...props}
        >
            <VirtualCardIssueProcess
                amountGive={context.dataRef.current?.amountGive ?? ''}
                amountReceive={context.dataRef.current?.amountReceive ?? ''}
                issueFees={context.dataRef.current?.issueFees ?? {}}
                cardholderName={context.dataRef.current?.cardholderName ?? ''}
                setView={setView}
                view={view}
                onClose={handleClose}
            />
        </Dialog>
    );
}
