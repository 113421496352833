import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardFeeEntryNode, useReplenishCardMutation } from 'apollo/main/generated';
import removeNonNumeric from 'utils/removeNonNumeric/removeNonNumeric';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import { useGlobalError, useIsTouchableDevice } from 'helpers';
import { calculateTopUp } from 'helpers/calculateVirtualCardFees/calculateVirtualCardFees';
import { Confirm, Expired, Method, Payment, Pending, TopUp } from '../VirtualCardManageViews/VirtualCardManageViews';
import { View } from '../VirtualCardManageViews/VirtualCardManageViews.d';

export interface VirtualCardTopUpProcessProps {
    cardId?: string;
    onClose?: () => void;
    setView: (s: View) => void;
    topUpFees?: CardFeeEntryNode;
    view: View;
}

export default function VirtualCardTopUpProcess({
    cardId,
    onClose,
    setView,
    topUpFees,
    view
}: VirtualCardTopUpProcessProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const isTouchableDevice = useIsTouchableDevice();

    const [amountGive, setAmountGive] = React.useState('');
    const [isAmountError, setIsAmountError] = React.useState(false);
    const [amountReceive, setAmountReceive] = React.useState('');
    const [memo, setMemo] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [receiver, setReceiver] = React.useState('');
    const [time, setTime] = React.useState('');

    const { fixedFee, rate, issuerRate, issuerFixedFee } = topUpFees ?? {};

    const [replenishCardMutation, { loading: replenishCardLoading }] = useReplenishCardMutation();

    const handleAmountGiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d+(\.\d{0,5})?)?$/;
        const numericValue = removeNonNumeric(e.target.value, false);

        setIsAmountError(false);

        if (!regex.test(numericValue)) return;

        setAmountGive(numericValue);

        if (numericValue) {
            const calculated = calculateTopUp(
                'receive',
                Number(numericValue),
                Number(fixedFee),
                Number(rate),
                Number(issuerRate),
                Number(issuerFixedFee)
            );

            if (calculated < 100 || calculated > 200_000) {
                setIsAmountError(true);
            }

            setAmountReceive(numToPrecisionStr(calculated, 2, false, 'ceil'));

            // debouncedAmountReceive({ give: numericValue });
        } else {
            setAmountReceive('');
        }
    };

    const handleAmountReceiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d+(\.\d{0,5})?)?$/;
        const numericValue = removeNonNumeric(e.target.value, false);

        setIsAmountError(false);

        if (!regex.test(numericValue)) return;

        setAmountReceive(numericValue);

        if (numericValue && (Number(numericValue) < 100 || Number(numericValue) > 200_000)) {
            setIsAmountError(true);
        }

        if (numericValue) {
            setAmountGive(
                numToPrecisionStr(
                    calculateTopUp(
                        'give',
                        Number(numericValue),
                        Number(fixedFee),
                        Number(rate),
                        Number(issuerRate),
                        Number(issuerFixedFee)
                    ),
                    2,
                    false,
                    'ceil'
                )
            );
        } else {
            setAmountGive('');
        }
    };

    const handleSubmit = () => {
        if (Number(amountReceive) < 100 || Number(amountReceive) > 200_000) {
            setIsAmountError(true);
        } else {
            setView(View.Confirm);
        }
    };

    const handleConfirmSubmit = () => {
        replenishCardMutation({
            variables: {
                input: {
                    cardId: cardId ?? '',
                    amount: amountReceive,
                    paymentCurrency: 'usdcash'
                }
            }
        }).then(({ data }) => {
            const topUp = data?.replenishCard;

            if (topUp?.__typename === 'CardActionNode') {
                const {
                    // id,
                    // type,
                    // status,
                    createdAt,
                    // eosAccount,
                    // amountToReceive,
                    // currencyToReceive,
                    paymentParams
                } = topUp ?? {};

                const {
                    currency: dataCurrency,
                    receiver: dataReceiver,
                    memo: dataMemo
                    // amount,
                    // expirationDate
                } = paymentParams ?? {};

                setMemo(dataMemo ?? '');
                setCurrency(dataCurrency ?? '');
                setReceiver(dataReceiver ?? '');
                // setTime(expirationDate ?? '')
                setTime(createdAt ?? '');

                setView(isTouchableDevice ? View.Method : View.Payment);
            } else {
                const { __typename, errorMessage = '' } = topUp ?? {};

                onGlobalError(t([`pageVirtualCard.issue.error.${__typename}`, errorMessage]));
            }
        });
    };

    if (view === View.Pending)
        return (
            <Pending
                give={`${amountGive} USDCASH`}
                receive={`$${amountReceive}`}
                rate={`1 USDCASH = ${issuerRate} USD`}
                onClose={onClose}
            />
        );

    if (view === View.Expired) return <Expired onClose={onClose} />;

    if (view === View.Confirm)
        return (
            <Confirm
                title={t('pageVirtualCard.topUp.title')}
                description={t('pageVirtualCard.topUp.description')}
                rate={`1 USDCASH = ${topUpFees?.issuerRate} USD`}
                give={`${amountGive} USDCASH`}
                receive={`$${amountReceive}`}
                onSubmit={handleConfirmSubmit}
                loading={replenishCardLoading}
            />
        );

    if (view === View.Method) return <Method memo={memo} setView={setView} amount={amountGive} />;

    if (view === View.Payment)
        return (
            <Payment
                title={t('pageVirtualCard.topUp.title')}
                time={time}
                memo={memo}
                receiver={receiver}
                amount={amountReceive}
                currency={currency}
                setView={setView}
            />
        );

    return (
        <TopUp
            amountMin="100"
            amountMax="200 000"
            amountGive={amountGive}
            amountReceive={amountReceive}
            isAmountError={isAmountError}
            issuerRate={topUpFees?.issuerRate}
            onAmountGiveChange={handleAmountGiveChange}
            onAmountReceiveChange={handleAmountReceiveChange}
            onSubmit={handleSubmit}
        />
    );
}
