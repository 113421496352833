import TetherLogo from 'assets/images/tokens/tether.svg';
import RublesLogo from 'assets/images/tokens/rubles.svg';
import RMBCLogo from 'assets/images/tokens/rmbc.svg';
import LQLogo from 'assets/images/tokens/lq.svg';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import ListLogo from 'assets/images/tokens/list.svg';
import EURCASHLogo from 'assets/images/tokens/eurcash.svg';
import GBPCASHLogo from 'assets/images/tokens/gbpcash.svg';
import INRCASHLogo from 'assets/images/tokens/inrcash.svg';
import JPYCASHLogo from 'assets/images/tokens/jpycash.svg';
import RMBCASHLogo from 'assets/images/tokens/rmbcash.svg';
import RUBCASHLogo from 'assets/images/tokens/rubcash.svg';
import UAHCASHLogo from 'assets/images/tokens/uahcash.svg';
import USDCASHLogo from 'assets/images/tokens/usdcash.svg';
import URALSLogo from 'assets/images/tokens/urals.png';

export default function getTokenLogo(logo?: string | null, code?: string | null, smartContract?: string | null) {
    if (smartContract === 'uralsuralsru' || (smartContract === 'testuralsurl' && code === 'URALS')) {
        return URALSLogo;
    }

    if (smartContract === 'aabw2r.pcash' && code === 'USDT') {
        return TetherLogo;
    }

    if ((smartContract === 'rublesrubles' || smartContract === 'testrublesrb') && code === 'RUBLES') {
        return RublesLogo;
    }

    if ((smartContract === 'renminbi' || smartContract === 'testrenminbi') && code === 'RMBC') {
        return RMBCLogo;
    }

    if (smartContract === 'swap.pcash' && code?.toUpperCase()?.startsWith('LQ')) {
        return LQLogo;
    }

    if ((smartContract === 'swap.pcash' || smartContract === 'testnewswap1') && code === 'MLNK') {
        return MLNKLogo;
    }

    if (smartContract === 'token.list' && code?.toUpperCase()?.startsWith('LI')) {
        return ListLogo;
    }

    if ((smartContract === 'token.pcash' || smartContract === 'cashescashes') && code?.includes('CASH')) {
        switch (code) {
            case 'EURCASH':
                return EURCASHLogo;
            case 'GBPCASH':
                return GBPCASHLogo;
            case 'INRCASH':
                return INRCASHLogo;
            case 'JPYCASH':
                return JPYCASHLogo;
            case 'RMBCASH':
                return RMBCASHLogo;
            case 'RUBCASH':
                return RUBCASHLogo;
            case 'UAHCASH':
                return UAHCASHLogo;
            case 'USDCASH':
                return USDCASHLogo;
            default:
                return logo || '';
        }
    }

    return logo || '';
}
