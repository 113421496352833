import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CardTransactionNode, useMyCardHistoryQuery } from 'apollo/main/generated';
// import { useFormatDate } from 'helpers';
import { BagIcon, PlusIcon } from 'ui/Icons/Icons';
import { ListSkeleton } from './VirtualCardHistorySkeleton';
import styles from './VirtualCardHistory.module.scss';

export interface VirtualCardHistoryProps {
    cardId?: string;
}

const getDateOrTime = (dateTimeString: string, value: 'date' | 'time' = 'date') =>
    dateTimeString.split('T')[value === 'date' ? 0 : 1];

const groupDataByDate = (data?: (CardTransactionNode | null)[]) => {
    if (!data?.length) return [];

    return data.reduce<CardTransactionNode[][]>((acc, item) => {
        const index = acc.findIndex((group) => getDateOrTime(group[0].createdAt) === getDateOrTime(item?.createdAt));

        if (item) {
            if (index !== -1) {
                acc[index].push(item);
            } else {
                acc.push([item]);
            }
        }

        return acc;
    }, []);
};

function Item({ amount, type, createdAt }: Pick<CardTransactionNode, 'amount' | 'type' | 'createdAt'>) {
    const [t] = useTranslation();

    const currentType = type === 'TransferIn' ? t('pageVirtualCard.history.topUp') : t('pageVirtualCard.history.spend');

    return (
        <div className={cn(styles.ItemRoot, type === 'TransferIn' && styles.ItemRootTopUp)}>
            <div className={styles.ItemStatus}>
                <div className={cn(styles.ItemIconContainer, styles.ItemIconContainerBackground)}>
                    {type === 'TransferIn' ? (
                        <PlusIcon className={styles.ItemIcon} />
                    ) : (
                        <BagIcon className={styles.ItemIcon} />
                    )}
                </div>
                <div className={styles.ItemInfo}>
                    <div className={styles.ItemAction}>{currentType}</div>
                    <div className={styles.ItemTime}>
                        {DateTime.fromISO(createdAt, { zone: 'local' }).toFormat('HH:mm')}
                    </div>
                </div>
            </div>
            <div className={styles.ItemValue}>${amount}</div>
        </div>
    );
}

export default function VirtualCardHistory({ cardId = '' }: VirtualCardHistoryProps) {
    const [t, { language }] = useTranslation();
    // const formatDate = useFormatDate();

    const { data, loading } = useMyCardHistoryQuery({
        skip: !cardId,
        variables: { cardId }
        // context: { headers: { Authorization: `JWT ${getToken()}` } }
    });

    const { transactions } = data?.myCardHistory ?? {};
    const groupTransactionsByDate = groupDataByDate(transactions || []);

    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>{t('pageVirtualCard.history.title')}</h3>
            <div className={styles.ListContainer}>
                {loading && (
                    <ListSkeleton
                        items={[
                            ['', '', '', ''],
                            ['', '']
                        ]}
                    />
                )}

                {Boolean(transactions?.length) && (
                    <div className={styles.List}>
                        {groupTransactionsByDate.map((group) => (
                            <div key={group[0].createdAt} className={styles.ListItem}>
                                <div className={styles.Date}>
                                    {DateTime.fromISO(group[0].createdAt, { zone: 'local' })
                                        .setLocale(language)
                                        .toFormat('DD')}
                                </div>
                                {group.map((item) => {
                                    const { id, amount, type, createdAt } = item ?? {};

                                    return <Item key={id} amount={amount} type={type} createdAt={createdAt} />;
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
