import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardFeeEntryNode, CardPrivateInfoNode } from 'apollo/main/generated';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import Skeleton from 'ui/Skeleton/Skeleton';
import VirtualCardPlastic from '../VirtualCardPlastic/VirtualCardPlastic';
import VirtualCardTermsModal from '../VirtualCardTermsModal/VirtualCardTermsModal';
import VirtualCardAccount from '../VirtualCardAccount/VirtualCardAccount';
import VirtualCardTopUpModal from '../VirtualCardTopUpModal/VirtualCardTopUpModal';
import styles from './VirtualCardInfo.module.scss';

export interface VirtualCardInfoProps {
    account?: string;
    cardId?: string;
    balance?: string;
    cardholderName?: string;
    privateInfo?: CardPrivateInfoNode | null;
    issueFees?: CardFeeEntryNode;
    topUpFees?: CardFeeEntryNode;
    topUpAmountMin?: string;
    topUpPercent?: string;
}

export default function VirtualCardInfo({
    cardId,
    account,
    balance,
    privateInfo,
    cardholderName,
    issueFees,
    topUpFees,
    topUpAmountMin,
    topUpPercent
}: VirtualCardInfoProps) {
    const [t] = useTranslation();

    const termsModal = useDialog();
    const topUpModal = useDialog();

    const isIssued = privateInfo?.cardNumber;

    const {
        // fixedFee: issueFixedFee,
        issuerFixedFee: issueIssuerFixedFee
        // issuerRate: issueIssuerRate,
        // rate: issueRate
    } = issueFees ?? {};

    // const {
    //     fixedFee: topUpFixedFee,
    //     issuerFixedFee: topUpIssuerFixedFee,
    //     issuerRate: topUpIssuerRate,
    //     rate: topUpRate
    // } = topUpFees ?? {};

    const handleTermsClick = () => termsModal.setOpen(true);

    const handleTopUpClick = () => {
        topUpModal.context.dataRef.current = { cardId, issueFees, topUpFees };
        topUpModal.setOpen(true);
    };

    return (
        <>
            <div className={styles.Root}>
                <div className={styles.Account}>
                    <VirtualCardAccount account={account} />
                </div>
                <div>
                    {balance && (
                        <div className={styles.BalanceContainer}>
                            <div className={styles.Balance}>
                                <div className={styles.BalanceLabel}>{t('pageVirtualCard.card.balance')}</div>
                                <div className={styles.BalanceValue}>${balance}</div>
                            </div>
                            <Button onClick={handleTopUpClick}>{t('pageVirtualCard.button.topUp')}</Button>
                        </div>
                    )}
                    <div className={styles.Plastic}>
                        <VirtualCardPlastic cardholderName={cardholderName} privateInfo={privateInfo} />

                        {isIssued && (
                            <div className={styles.PlasticDescription}>{t('pageVirtualCard.card.description')}</div>
                        )}
                    </div>

                    {!isIssued && (
                        <div>
                            <div className={styles.Charge}>
                                <div>{t('pageVirtualCard.issue.title')}</div>
                                <div className={styles.ChargeValue}>
                                    {issueIssuerFixedFee ? (
                                        `$${issueIssuerFixedFee}`
                                    ) : (
                                        <Skeleton variant="text" width={60} />
                                    )}
                                </div>
                            </div>

                            {topUpPercent && (
                                <div className={styles.Charge}>
                                    <div>{t('pageVirtualCard.issue.terms.topUp')}</div>
                                    <div className={styles.ChargeValue}>{topUpPercent}%</div>
                                </div>
                            )}

                            {topUpAmountMin && (
                                <div className={styles.Charge}>
                                    <div>{t('pageVirtualCard.issue.terms.topUpMin')}</div>
                                    <div className={styles.ChargeValue}>${topUpAmountMin}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className={styles.ButtonContainer}>
                    <Button color="secondary" fullWidth onClick={handleTermsClick}>
                        {t('pageVirtualCard.button.terms')}
                    </Button>
                </div>
            </div>

            <VirtualCardTermsModal {...termsModal} />
            <VirtualCardTopUpModal {...topUpModal} />
        </>
    );
}
