import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CardFeeEntryNode } from 'apollo/main/generated';
import removeNonNumeric from 'utils/removeNonNumeric/removeNonNumeric';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import { calculateIssue } from 'helpers/calculateVirtualCardFees/calculateVirtualCardFees';
import useFormField from 'hooks/useFormField/useFormField';
import TextField from 'ui/TextField/TextField';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import VirtualCardIssueModal from '../VirtualCardIssueModal/VirtualCardIssueModal';
import VirtualCardAmount from '../VirtualCardAmount/VirtualCardAmount';
import styles from './VirtualCardIssue.module.scss';

export interface VirtualCardIssueProps {
    issueFees?: CardFeeEntryNode;
}

export default function VirtualCardIssue({ issueFees }: VirtualCardIssueProps) {
    const [t] = useTranslation();

    const issueModal = useDialog();

    const name = useFormField('');

    const [amountGive, setAmountGive] = React.useState('');
    const [amountReceive, setAmountReceive] = React.useState('');
    const [isAmountError, setIsAmountError] = React.useState(false);

    const { fixedFee, rate, issuerRate, issuerFixedFee } = issueFees ?? {};

    const handleAmountGiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d+(\.\d{0,5})?)?$/;
        const numericValue = removeNonNumeric(e.target.value, false);

        setIsAmountError(false);

        if (!regex.test(numericValue)) return;

        setAmountGive(numericValue);

        if (numericValue) {
            const calculated = calculateIssue(
                'receive',
                Number(numericValue),
                Number(fixedFee),
                Number(rate),
                Number(issuerRate),
                Number(issuerFixedFee)
            );

            if (calculated < 100 || calculated > 200_000) {
                setIsAmountError(true);
            }

            if (calculated > 0) {
                setAmountReceive(numToPrecisionStr(calculated, 2, false, 'ceil'));
            } else {
                setAmountReceive('');
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleAmountReceiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d+(\.\d{0,5})?)?$/;
        const numericValue = removeNonNumeric(e.target.value, false);

        setIsAmountError(false);

        if (!regex.test(numericValue)) return;

        setAmountReceive(numericValue);

        if (numericValue && (Number(numericValue) < 100 || Number(numericValue) > 200_000)) {
            setIsAmountError(true);
        }

        if (numericValue) {
            setAmountGive(
                numToPrecisionStr(
                    calculateIssue(
                        'give',
                        Number(numericValue),
                        Number(fixedFee),
                        Number(rate),
                        Number(issuerRate),
                        Number(issuerFixedFee)
                    ),
                    2,
                    false,
                    'ceil'
                )
            );
        } else {
            setAmountGive('');
        }
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value.replace(/[^\p{L}\s]/gu, '');

        name.errorChange(false);
        name.change(formattedValue);
    };

    const handleValidateName = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const words = value.split(/\s+/);

        if (value && words.length < 2) {
            name.errorChange(true);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (Number(amountReceive) < 100 || Number(amountReceive) > 200_000) {
            setIsAmountError(true);
        } else {
            issueModal.context.dataRef.current = { cardholderName: name.value, issueFees, amountGive, amountReceive };
            issueModal.setOpen(true);
        }
    };

    return (
        <>
            <div className={styles.Root}>
                <form id="form-virtual-card-issue" className={styles.Form} onSubmit={handleSubmit}>
                    <h3 className={styles.Title}>{t('pageVirtualCard.issue.title')}</h3>
                    <div className={styles.FormItem}>
                        <h4 className={styles.Label}>{t('pageVirtualCard.issue.name.label')}</h4>
                        <TextField
                            classes={{ input: styles.InputUppercase }}
                            value={name.value}
                            onChange={handleChangeName}
                            placeholder={t('pageVirtualCard.issue.name.placeholder')}
                            isError={name.error}
                            onBlur={handleValidateName}
                        />
                        <div className={cn(styles.Description, name.error && styles.DescriptionError)}>
                            {t('pageVirtualCard.issue.name.helperText')}
                        </div>
                    </div>
                    <div className={styles.FormItem}>
                        <h4 className={styles.Label}>{t('pageVirtualCard.amount.title')}</h4>
                        <div className={styles.Amount}>
                            <VirtualCardAmount
                                amountGiveValue={amountGive}
                                onAmountGiveChange={handleAmountGiveChange}
                                amountReceiveValue={amountReceive}
                                onAmountReceiveChange={handleAmountReceiveChange}
                                isError={isAmountError}
                            />
                        </div>
                        <div className={styles.Top}>
                            <div className={styles.Charge}>
                                <div>{t('pageVirtualCard.amount.rate')}</div>
                                <div className={styles.ChargeValue}>1 USDCASH = {issuerRate} USD</div>
                            </div>
                            <div className={styles.Charge}>
                                <div>{t('pageVirtualCard.issue.title')}</div>
                                <div className={styles.ChargeValue}>10 USD</div>
                            </div>
                            <div className={cn(styles.Charge, isAmountError && styles.ChargeError)}>
                                <div>{`${t('pageVirtualCard.amount.min')} - ${t('pageVirtualCard.amount.max')}`}</div>
                                <div className={styles.ChargeValue}>100 - 200 000 USD</div>
                            </div>
                        </div>
                        <div className={styles.Bottom}>
                            <div className={styles.Charge}>
                                <div>{t('pageVirtualCard.amount.summaryGive')}</div>
                                <div className={cn(styles.ChargeValue, styles.ChargeValueTotal)}>
                                    {Number(amountGive) > 0 ? amountGive : '-'} USDCASH
                                </div>
                            </div>
                            <div className={styles.Charge}>
                                <div>{t('pageVirtualCard.amount.summaryReceive')}</div>
                                <div className={cn(styles.ChargeValue, styles.ChargeValueTotal)}>
                                    {Number(amountReceive) > 0 ? amountReceive : '-'} USD
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className={styles.SubmitContainer}>
                    <Button
                        fullWidth
                        form="form-virtual-card-issue"
                        disabled={
                            !name.value ||
                            name.value.split(/\s+/).length < 2 ||
                            !amountGive ||
                            !amountReceive ||
                            isAmountError
                        }
                    >
                        {t('pageVirtualCard.button.continue')}
                    </Button>
                </div>
            </div>

            <VirtualCardIssueModal {...issueModal} />
        </>
    );
}
