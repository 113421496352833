import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Void: any;
};

export type AuthDeeplinkResponse = {
    __typename: 'AuthDeeplinkResponse';
    deeplink: Scalars['String'];
    requestId: Scalars['String'];
};

export type AuthDeeplinkResponseErrorResponse = AuthDeeplinkResponse | ErrorResponse;

export type AuthTokenResponse = {
    __typename: 'AuthTokenResponse';
    accessToken: Scalars['String'];
    accessTokenExpiresAt: Scalars['DateTime'];
    refreshToken: Scalars['String'];
};

export type AuthTokenResponseErrorResponse = AuthTokenResponse | ErrorResponse;

export type ErrorResponse = {
    __typename: 'ErrorResponse';
    detail: Scalars['String'];
    statusCode: Scalars['Int'];
};

export type Mutation = {
    __typename: 'Mutation';
    authGenerateDeeplink: AuthDeeplinkResponseErrorResponse;
    authGetAccessToken: AuthTokenResponseErrorResponse;
    authLogout?: Maybe<Scalars['Void']>;
    authRefreshToken: AuthTokenResponseErrorResponse;
};

export type MutationAuthGenerateDeeplinkArgs = {
    app: Scalars['String'];
    aud: Scalars['String'];
    siteName?: InputMaybe<Scalars['String']>;
};

export type MutationAuthGetAccessTokenArgs = {
    authRequestId: Scalars['String'];
};

export type MutationAuthLogoutArgs = {
    accessToken: Scalars['String'];
};

export type MutationAuthRefreshTokenArgs = {
    refreshToken: Scalars['String'];
};

export type Query = {
    __typename: 'Query';
    serviceName: Scalars['String'];
};

export type Subscription = {
    __typename: 'Subscription';
    waitAccessToken: AuthTokenResponseErrorResponse;
};

export type SubscriptionWaitAccessTokenArgs = {
    authRequestId: Scalars['String'];
};

export type AuthGenerateDeeplinkMutationVariables = Exact<{
    app: Scalars['String'];
    aud: Scalars['String'];
}>;

export type AuthGenerateDeeplinkMutation = {
    __typename: 'Mutation';
    authGenerateDeeplink:
        | { __typename: 'AuthDeeplinkResponse'; requestId: string; deeplink: string }
        | { __typename: 'ErrorResponse'; statusCode: number; detail: string };
};

export type AuthGetAccessTokenMutationVariables = Exact<{
    authRequestId: Scalars['String'];
}>;

export type AuthGetAccessTokenMutation = {
    __typename: 'Mutation';
    authGetAccessToken:
        | { __typename: 'AuthTokenResponse'; accessToken: string; refreshToken: string; accessTokenExpiresAt: any }
        | { __typename: 'ErrorResponse'; statusCode: number; detail: string };
};

export type AuthLogoutMutationVariables = Exact<{
    accessToken: Scalars['String'];
}>;

export type AuthLogoutMutation = { __typename: 'Mutation'; authLogout?: any | null };

export type AuthRefreshTokenMutationVariables = Exact<{
    refreshToken: Scalars['String'];
}>;

export type AuthRefreshTokenMutation = {
    __typename: 'Mutation';
    authRefreshToken:
        | { __typename: 'AuthTokenResponse'; accessToken: string; refreshToken: string; accessTokenExpiresAt: any }
        | { __typename: 'ErrorResponse'; statusCode: number; detail: string };
};

export type WaitAccessTokenSubscriptionVariables = Exact<{
    authRequestId: Scalars['String'];
}>;

export type WaitAccessTokenSubscription = {
    __typename: 'Subscription';
    waitAccessToken:
        | { __typename: 'AuthTokenResponse'; accessToken: string; refreshToken: string; accessTokenExpiresAt: any }
        | { __typename: 'ErrorResponse'; statusCode: number; detail: string };
};

export const AuthGenerateDeeplinkDocument = gql`
    mutation AuthGenerateDeeplink($app: String!, $aud: String!) {
        authGenerateDeeplink(app: $app, aud: $aud) {
            ... on AuthDeeplinkResponse {
                requestId
                deeplink
            }
            ... on ErrorResponse {
                statusCode
                detail
            }
        }
    }
`;
export type AuthGenerateDeeplinkMutationFn = Apollo.MutationFunction<
    AuthGenerateDeeplinkMutation,
    AuthGenerateDeeplinkMutationVariables
>;

/**
 * __useAuthGenerateDeeplinkMutation__
 *
 * To run a mutation, you first call `useAuthGenerateDeeplinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthGenerateDeeplinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authGenerateDeeplinkMutation, { data, loading, error }] = useAuthGenerateDeeplinkMutation({
 *   variables: {
 *      app: // value for 'app'
 *      aud: // value for 'aud'
 *   },
 * });
 */
export function useAuthGenerateDeeplinkMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthGenerateDeeplinkMutation, AuthGenerateDeeplinkMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AuthGenerateDeeplinkMutation, AuthGenerateDeeplinkMutationVariables>(
        AuthGenerateDeeplinkDocument,
        options
    );
}
export type AuthGenerateDeeplinkMutationHookResult = ReturnType<typeof useAuthGenerateDeeplinkMutation>;
export type AuthGenerateDeeplinkMutationResult = Apollo.MutationResult<AuthGenerateDeeplinkMutation>;
export type AuthGenerateDeeplinkMutationOptions = Apollo.BaseMutationOptions<
    AuthGenerateDeeplinkMutation,
    AuthGenerateDeeplinkMutationVariables
>;
export const AuthGetAccessTokenDocument = gql`
    mutation AuthGetAccessToken($authRequestId: String!) {
        authGetAccessToken(authRequestId: $authRequestId) {
            ... on AuthTokenResponse {
                accessToken
                refreshToken
                accessTokenExpiresAt
            }
            ... on ErrorResponse {
                statusCode
                detail
            }
        }
    }
`;
export type AuthGetAccessTokenMutationFn = Apollo.MutationFunction<
    AuthGetAccessTokenMutation,
    AuthGetAccessTokenMutationVariables
>;

/**
 * __useAuthGetAccessTokenMutation__
 *
 * To run a mutation, you first call `useAuthGetAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthGetAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authGetAccessTokenMutation, { data, loading, error }] = useAuthGetAccessTokenMutation({
 *   variables: {
 *      authRequestId: // value for 'authRequestId'
 *   },
 * });
 */
export function useAuthGetAccessTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthGetAccessTokenMutation, AuthGetAccessTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AuthGetAccessTokenMutation, AuthGetAccessTokenMutationVariables>(
        AuthGetAccessTokenDocument,
        options
    );
}
export type AuthGetAccessTokenMutationHookResult = ReturnType<typeof useAuthGetAccessTokenMutation>;
export type AuthGetAccessTokenMutationResult = Apollo.MutationResult<AuthGetAccessTokenMutation>;
export type AuthGetAccessTokenMutationOptions = Apollo.BaseMutationOptions<
    AuthGetAccessTokenMutation,
    AuthGetAccessTokenMutationVariables
>;
export const AuthLogoutDocument = gql`
    mutation AuthLogout($accessToken: String!) {
        authLogout(accessToken: $accessToken)
    }
`;
export type AuthLogoutMutationFn = Apollo.MutationFunction<AuthLogoutMutation, AuthLogoutMutationVariables>;

/**
 * __useAuthLogoutMutation__
 *
 * To run a mutation, you first call `useAuthLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLogoutMutation, { data, loading, error }] = useAuthLogoutMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useAuthLogoutMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthLogoutMutation, AuthLogoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AuthLogoutMutation, AuthLogoutMutationVariables>(AuthLogoutDocument, options);
}
export type AuthLogoutMutationHookResult = ReturnType<typeof useAuthLogoutMutation>;
export type AuthLogoutMutationResult = Apollo.MutationResult<AuthLogoutMutation>;
export type AuthLogoutMutationOptions = Apollo.BaseMutationOptions<AuthLogoutMutation, AuthLogoutMutationVariables>;
export const AuthRefreshTokenDocument = gql`
    mutation AuthRefreshToken($refreshToken: String!) {
        authRefreshToken(refreshToken: $refreshToken) {
            ... on AuthTokenResponse {
                accessToken
                refreshToken
                accessTokenExpiresAt
            }
            ... on ErrorResponse {
                statusCode
                detail
            }
        }
    }
`;
export type AuthRefreshTokenMutationFn = Apollo.MutationFunction<
    AuthRefreshTokenMutation,
    AuthRefreshTokenMutationVariables
>;

/**
 * __useAuthRefreshTokenMutation__
 *
 * To run a mutation, you first call `useAuthRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshTokenMutation, { data, loading, error }] = useAuthRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthRefreshTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthRefreshTokenMutation, AuthRefreshTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AuthRefreshTokenMutation, AuthRefreshTokenMutationVariables>(
        AuthRefreshTokenDocument,
        options
    );
}
export type AuthRefreshTokenMutationHookResult = ReturnType<typeof useAuthRefreshTokenMutation>;
export type AuthRefreshTokenMutationResult = Apollo.MutationResult<AuthRefreshTokenMutation>;
export type AuthRefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    AuthRefreshTokenMutation,
    AuthRefreshTokenMutationVariables
>;
export const WaitAccessTokenDocument = gql`
    subscription WaitAccessToken($authRequestId: String!) {
        waitAccessToken(authRequestId: $authRequestId) {
            ... on AuthTokenResponse {
                accessToken
                refreshToken
                accessTokenExpiresAt
            }
            ... on ErrorResponse {
                statusCode
                detail
            }
        }
    }
`;

/**
 * __useWaitAccessTokenSubscription__
 *
 * To run a query within a React component, call `useWaitAccessTokenSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWaitAccessTokenSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitAccessTokenSubscription({
 *   variables: {
 *      authRequestId: // value for 'authRequestId'
 *   },
 * });
 */
export function useWaitAccessTokenSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<WaitAccessTokenSubscription, WaitAccessTokenSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<WaitAccessTokenSubscription, WaitAccessTokenSubscriptionVariables>(
        WaitAccessTokenDocument,
        options
    );
}
export type WaitAccessTokenSubscriptionHookResult = ReturnType<typeof useWaitAccessTokenSubscription>;
export type WaitAccessTokenSubscriptionResult = Apollo.SubscriptionResult<WaitAccessTokenSubscription>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AuthDeeplinkResponseErrorResponse: ['AuthDeeplinkResponse', 'ErrorResponse'],
        AuthTokenResponseErrorResponse: ['AuthTokenResponse', 'ErrorResponse']
    }
};
export default result;
