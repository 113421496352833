import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { DialogBody, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import styles from './VirtualCardTermsModal.module.scss';

export default function VirtualCardTermsModal({ open, setOpen, ...props }: ReturnType<typeof useDialog>) {
    const [t] = useTranslation();

    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <Dialog
            open={open}
            onOpenChange={setOpen}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            {...props}
        >
            <DialogHeader title={t('pageVirtualCard.terms.title')} classes={{ title: styles.Title }} />
            <DialogBody>
                <ul className={styles.List}>
                    {(
                        t('pageVirtualCard.terms.list', { returnObjects: true }) as {
                            label: string;
                            value: string;
                        }[]
                    ).map(({ label, value }) => (
                        <li key={label} className={styles.Item}>
                            <div className={styles.Label}>{label}</div>
                            <div className={styles.Value}>{value}</div>
                        </li>
                    ))}
                </ul>
            </DialogBody>
        </Dialog>
    );
}
