import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CardFeeEntryNode } from 'apollo/main/generated';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Button from 'ui/Button/Button';
import { ClockIcon, WarningFilledIcon } from 'ui/Icons/Icons';
import { useDialog } from 'ui/Dialog/Dialog';
import VirtualCardAccount from '../VirtualCardAccount/VirtualCardAccount';
import VirtualCardBackground from '../VirtualCardBackground/VirtualCardBackground';
import VirtualCardTermsModal from '../VirtualCardTermsModal/VirtualCardTermsModal';
import VirtualCardTopUpModal from '../VirtualCardTopUpModal/VirtualCardTopUpModal';
import styles from './VirtualCardStatus.module.scss';

interface StatusProps {
    onClick?: () => void;
}

export interface VirtualCardStatusProps {
    account?: string;
    status?: string;
    cardId?: string;
    issueFees?: CardFeeEntryNode;
    topUpFees?: CardFeeEntryNode;
}

function VirtualCardStatusPending({ onClick }: StatusProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <div className={styles.Status}>
                <ClockIcon className={styles.StatusIcon} />
                {t('pageVirtualCard.status.pending.title')}
            </div>
            <h1 className={styles.Title}>{t('pageVirtualCard.status.title')}</h1>
            <div className={styles.Text}>{t('pageVirtualCard.status.pending.description')}</div>
            <Button fullWidth={isMobileOrLess} onClick={onClick}>
                {t('pageVirtualCard.button.terms')}
            </Button>
        </>
    );
}

function VirtualCardStatusUnpaid({ onClick }: StatusProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <div className={cn(styles.Status, styles.StatusWarning)}>
                <ClockIcon className={styles.StatusIcon} />
                {t('pageVirtualCard.status.unpaid.title')}
            </div>
            <h1 className={styles.Title}>{t('pageVirtualCard.status.title')}</h1>
            <div className={styles.Text}>{t('pageVirtualCard.status.unpaid.description')}</div>
            <Button fullWidth={isMobileOrLess} onClick={onClick}>
                {t('pageVirtualCard.button.toPay')}
            </Button>
        </>
    );
}

function VirtualCardStatusError({ onClick }: StatusProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <div className={cn(styles.Status, styles.StatusError)}>
                <WarningFilledIcon className={styles.StatusIcon} />
                {t('pageVirtualCard.status.fail.title')}
            </div>
            <h1 className={styles.Title}>{t('pageVirtualCard.status.fail.title')}</h1>
            <div className={styles.Text}>{t('pageVirtualCard.status.fail.description')}</div>
            <Button fullWidth={isMobileOrLess} onClick={onClick}>
                {t('pageVirtualCard.button.support')}
            </Button>
        </>
    );
}

export default function VirtualCardStatus({
    account,
    cardId,
    children,
    issueFees,
    status,
    topUpFees
}: React.PropsWithChildren<VirtualCardStatusProps>) {
    const termsModal = useDialog();
    const topUpModal = useDialog();

    const handleTermsClick = () => termsModal.setOpen(true);

    const handleTopUpClick = () => {
        topUpModal.context.dataRef.current = { cardId, issueFees, topUpFees };
        topUpModal.setOpen(true);
    };

    const handleSupportClick = () => {
        window.location.href = 'https://t.me/paycashchat';
    };

    return (
        <>
            <div className={styles.Root}>
                <div className={styles.Account}>{account && <VirtualCardAccount account={account} />}</div>
                <VirtualCardBackground classes={{ background: styles.Background }}>
                    <div className={styles.Content}>
                        {children || (
                            <>
                                {status === 'pending' && <VirtualCardStatusPending onClick={handleTermsClick} />}
                                {status === 'unpaid' && <VirtualCardStatusUnpaid onClick={handleTopUpClick} />}
                                {status === 'error' && <VirtualCardStatusError onClick={handleSupportClick} />}
                            </>
                        )}
                    </div>
                </VirtualCardBackground>
            </div>

            <VirtualCardTermsModal {...termsModal} />
            <VirtualCardTopUpModal {...topUpModal} />
        </>
    );
}
