import React from 'react';

export enum View {
    Confirm,
    TopUp,
    Method,
    Payment,
    Pending,
    Expired
}

export interface ConfirmProps {
    title?: string;
    description?: string;
    rate?: string;
    valueName?: string;
    give?: string;
    valueIssue?: string;
    receive?: string;
    loading?: boolean;
    onSubmit?: () => void;
    setView?: (s: View) => void;
}

export interface ExpiredProps {
    description?: string;
    onClose?: () => void;
}

export interface MethodProps {
    amount?: string;
    loading?: boolean;
    setView?: (s: View) => void;
    memo?: string;
}

export interface TopUpProps {
    isAmountError?: boolean;
    amountGive: string;
    onAmountGiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    amountReceive: string;
    onAmountReceiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit?: () => void;
    issuerRate?: string;
    amountMin?: string;
    amountMax?: string;
}
